import authProvider from './AuthProvider'

export const getToken = async () => {
  const token = await authProvider.getAccessToken()
  return `bearer ${token.accessToken}`
}

export const pushDownloadFile = async (fileContentsUrl, shouldAuthorize = true) => {
  const Authorization = shouldAuthorize ? await getToken() : ''
  const response = await fetch(
    fileContentsUrl,
    shouldAuthorize
      ? {
          headers: {
            Authorization,
          },
        }
      : undefined
  )
  const pdf = await response.blob()
  const file = window.URL.createObjectURL(pdf)
  const a = document.createElement('a')
  a.href = file
  a.download = 'contract'
  document.body.appendChild(a)
  a.click()
}

export const sendAssignmentInstruction = async ($id) => {
  const Authorization = await getToken()
  const request = [
    `${window._env_.REACT_APP_BASEURL}/v1/ip/assignment/instruction`,
    {
      method: 'post',
      headers: {
        Authorization,
        'Content-type': 'application/json',
        Accept: 'application/json',
        'Accept-Charset': 'utf-8',
      },
      body: JSON.stringify({
        id: $id,
      }),
    },
  ]

  const response = await fetch(request[0], request[1])
  await response.json()
}

export const postAssignment = async (data) => {
  const Authorization = await getToken()
  const response = await fetch(`${window._env_.REACT_APP_BASEURL}/v1/ip/assignment`, {
    method: 'post',
    headers: {
      Authorization,
      'Content-type': 'application/json',
      Accept: 'application/json',
      'Accept-Charset': 'utf-8',
    },
    body: JSON.stringify(data),
  })
  await response.json()
}

export const updateAssignment = async (data) => {
  const Authorization = await getToken()
  const response = await fetch(`${window._env_.REACT_APP_BASEURL}/v1/ip/assignment/update`, {
    method: 'post',
    headers: {
      Authorization,
      'Content-type': 'application/json',
      Accept: 'application/json',
      'Accept-Charset': 'utf-8',
    },
    body: JSON.stringify(data),
  })
  await response.json()
}

export const getAssignments = async () => {
  const Authorization = await getToken()
  try {
    const response = await fetch(window._env_.REACT_APP_BASEURL + '/v1/ip/assignments', {
      headers: {
        Authorization,
      },
    })

    const resJSON = await response.json()
    if (resJSON.statusCode === 401) return []
    return resJSON
  } catch (e) {
    console.log('error when retrieving assignments')
    return []
  }
}

export const sendContractForSignature = async ($data) => {
  const Authorization = await getToken()
  const response = await fetch(`${window._env_.REACT_APP_BASEURL}/v1/ip/assignment/update`, {
    method: 'post',
    headers: {
      Authorization,
      'Content-type': 'application/json',
      Accept: 'application/json',
      'Accept-Charset': 'utf-8',
    },
    body: JSON.stringify({
      id: $data.id,
      status: 'WaitingForInventorSignature',
    }),
  })

  await response.json()
}
