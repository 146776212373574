import React from 'react'
import ReactDOM from 'react-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import App from './App'
import { AzureAD } from 'react-aad-msal'
import AuthProvider from './AuthProvider'

ReactDOM.render(
  <AzureAD provider={AuthProvider} forceLogin={true}>
    <App />
  </AzureAD>,
  document.getElementById('root')
)
