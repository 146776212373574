import React, { useState } from 'react'
import { postAssignment, updateAssignment } from './utils'
import InventorAssignmentList from './InventorAssignmentList'
import InventorAssignmentForm from './InventorAssignmentForm'

const InventorAssignment = () => {
  const [view, updateView] = useState('list')
  const [editInitialData, updateEditInitialData] = useState(null)

  if (view === 'list') {
    return (
      <InventorAssignmentList
        createOwnershipCallback={() => updateView('create')}
        modifyAssignmentCallback={(newInitialData) => {
          updateEditInitialData(newInitialData)
          return updateView('edit')
        }}
      />
    )
  }

  if (view === 'create') {
    return <InventorAssignmentForm postAssignment={postAssignment} listOwnershipCallback={() => updateView('list')} />
  }

  return (
    <InventorAssignmentForm
      postAssignment={updateAssignment}
      listOwnershipCallback={() => updateView('list')}
      initialData={editInitialData}
    />
  )
}

export default InventorAssignment
