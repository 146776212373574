import React from 'react'
import InventorAssignment from './InventorAssignment'
import Header from './components/Header'
import './css/App.css'

const App = () => (
  <div className="App">
    <Header />
    <InventorAssignment />
  </div>
)

export default App
