import React, { useEffect, useState } from 'react'
import './css/InventorAssignmentList.css'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Formik } from 'formik'
import * as Yup from 'yup'

const defaultData = {
  customerReference: '',
  assigneeName: '',
  assigneeEmail: '',
  attorneyEmail: '',
  attorneyName: '',
  attorneyCompany: '',
  assigneeCompany: '',
  addressLineOne: '',
  addressLineTwo: '',
  title: '',
  applicationNumber: '',
  country: '',
}

const InventorAssignmentForm = ({ listOwnershipCallback, postAssignment, initialData = null }) => {
  const [countries, updateCountries] = useState([])

  const validationSchema = Yup.object().shape({
    customerReference: Yup.string()
      .min(2, '*Customer Reference is required')
      .required('*Customer Reference is required'),
    assigneeName: Yup.string().min(2, '*Assignee name is required').required('*Assignee name is required'),
    attorneyName: Yup.string().min(2, '*Attorney name is required').required('*Attorney name is required'),
    attorneyCompany: Yup.string().min(2, '*Company name is required').required('*Company name is required'),
    assigneeCompany: Yup.string().min(2, '*Company name is required').required('*Company name is required'),
    country: Yup.string().min(2, '*Country is required').required('*Country is required'),
    assigneeEmail: Yup.string()
      .email('*Must be a valid email address')
      .max(100, '*Assignee email must be less than 100 characters')
      .required('*Email is required'),
    attorneyEmail: Yup.string()
      .email('*Must be a valid email address')
      .max(100, '*Attorney email must be less than 100 characters')
      .required('*Attorney email is required'),
    addressLineOne: Yup.string().min(2, 'Address Line 1 is required').required('Address Line 1 is required'),
    title: Yup.string().min(2, 'Title is required').required('Title is required'),
    applicationNumber: Yup.string().min(2, 'Application Number is required').required('Application Number is required'),
  })

  const flattenInitialData = ($initialData) => {
    const cap1st = (str) => (str === '' ? '' : str[0].toUpperCase() + str.slice(1))
    let oldAssigneeData = $initialData.oldAssignee || {}
    let newAssigneeData = $initialData.newAssignee || {}
    let payloadData = $initialData.payload || {}
    let $data = Object.assign({}, $initialData)
    delete $data.oldAssignee
    delete $data.newAssignee
    delete $data.payload
    return Object.assign(
      $data,
      payloadData,
      Object.fromEntries(Object.entries(oldAssigneeData).map((x) => ['assignee' + cap1st(x[0]), x[1]])),
      Object.fromEntries(Object.entries(newAssigneeData).map((x) => ['attorney' + cap1st(x[0]), x[1]])),
      { internalStatus: '' }
    )
  }

  useEffect(() => {
    const getCountries = async () => {
      const countriesRes = await fetch('https://restcountries.eu/rest/v2/all?fields=name')
      const countriesJSON = await countriesRes.json()
      updateCountries(countriesJSON.map((c) => c.name))
    }
    getCountries()
  }, [])

  return (
    <div className="assignment">
      <div className="assignments-header" style={{ justifyContent: 'space-around' }}>
        <div className="assignments-header-text">Create change of ownership contract</div>
        <Button variant="outline-danger" onClick={listOwnershipCallback}>
          Discard
        </Button>
      </div>

      <Row>
        <Col className="offset-2 col-8">
          <Formik
            initialValues={flattenInitialData(initialData || defaultData)}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true)

              // this is an extra field (payload) for additional data if needed
              const data = {
                customerReference: values.customerReference,
                country: values.country,
                filingDate: values.filingDate,
                oldAssignee: {
                  name: values.assigneeName,
                  email: values.assigneeEmail,
                  company: values.assigneeCompany,
                },
                newAssignee: {
                  name: values.attorneyName,
                  email: values.attorneyEmail,
                  company: values.attorneyCompany,
                },
                payload: {
                  addressLineOne: values.addressLineOne,
                  addressLineTwo: values.addressLineTwo,
                  title: values.title,
                  applicationNumber: values.applicationNumber,
                },
              }

              if (initialData) {
                // This is an edit request, we must provide ID
                data.id = initialData.id

                // In case an internalStatus is provided,
                // this means to submit status as well
                // Happens when you click the Submit button in Edit mode
                if (values.internalStatus !== '') {
                  data.status = values.internalStatus
                }
              } else {
                // This is a create request, when created through UI,
                // we set the status to WaitingForInventorSignature
                data.status = 'WaitingForInventorSignature'
              }

              await postAssignment(data)

              resetForm()
              setSubmitting(false)

              window.location.reload()
            }}
          >
            {({ values, errors, touched, handleChange, handleSubmit, setFieldValue, handleBlur, isSubmitting }) => {
              return (
                <Form noValidate onSubmit={handleSubmit}>
                  <div className="title">Application details</div>
                  <Form.Group controlId="formBasicCustomerRef" className="form-input">
                    <Form.Label>Customer reference</Form.Label>
                    <Form.Control
                      type="text"
                      name="customerReference"
                      placeholder="Enter customer reference"
                      onChange={handleChange}
                      value={values.customerReference}
                      className={touched.customerReference && errors.customerReference ? 'error' : ''}
                    />
                    {touched.customerReference && errors.customerReference && (
                      <div className="error-message">{errors.customerReference}</div>
                    )}
                  </Form.Group>
                  <Form.Group controlId="formBasicTitle" className="form-input">
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="text"
                      name="title"
                      placeholder="Enter Title"
                      onChange={handleChange}
                      value={values.title}
                      className={touched.title && errors.title ? 'error' : ''}
                    />
                    {touched.title && errors.title && <div className="error-message">{errors.title}</div>}
                  </Form.Group>
                  <Form.Group controlId="formBasicCountry" className="form-input">
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                      as="select"
                      name="country"
                      placeholder="Enter Country"
                      onChange={handleChange}
                      value={values.country}
                      className={touched.country && errors.country ? 'error' : ''}
                    >
                      {touched.country && errors.country && <div className="error-message">{errors.country}</div>}
                      {countries.map((country, index) => (
                        <option key={index}>{country}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="formBasicApplicationNumber" className="form-input">
                    <Form.Label>Application Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="applicationNumber"
                      placeholder="Enter application Number"
                      onChange={handleChange}
                      value={values.applicationNumber}
                      className={touched.applicationNumber && errors.applicationNumber ? 'error' : ''}
                    />
                    {touched.applicationNumber && errors.applicationNumber && (
                      <div className="error-message">{errors.applicationNumber}</div>
                    )}
                  </Form.Group>
                  <Form.Group controlId="formBasicDate" className="col-4 p-0 form-input">
                    <Form.Label>Filing Date</Form.Label>
                    <Form.Control
                      type="date"
                      name="filingDate"
                      onChange={handleChange}
                      value={values.filingDate}
                      className={touched.filingDate && errors.filingDate ? 'error' : ''}
                    />
                    {touched.filingDate && errors.filingDate && (
                      <div className="error-message">{errors.filingDate}</div>
                    )}
                  </Form.Group>

                  <div className="title">Old assignee details</div>
                  <Form.Group controlId="formBasicAssigneeCompany" className="form-input">
                    <Form.Label>Company name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter company name"
                      name="assigneeCompany"
                      onChange={handleChange}
                      value={values.assigneeCompany}
                      className={touched.assigneeCompany && errors.assigneeCompany ? 'error' : ''}
                    />
                    {touched.assigneeCompany && errors.assigneeCompany && (
                      <div className="error-message">{errors.assigneeCompany}</div>
                    )}
                  </Form.Group>
                  <Form.Group controlId="formBasicAssigneeName" className="form-input">
                    <Form.Label>Assignee Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter assignee name"
                      name="assigneeName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.assigneeName}
                      className={touched.assigneeName && errors.assigneeName ? 'error' : ''}
                    />
                    {touched.assigneeName && errors.assigneeName && (
                      <div className="error-message">{errors.assigneeName}</div>
                    )}
                  </Form.Group>
                  <Form.Group controlId="formBasicAssigneeEmail" className="form-input">
                    <Form.Label>Assignee email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter assignee email"
                      name="assigneeEmail"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.assigneeEmail}
                      className={touched.assigneeEmail && errors.assigneeEmail ? 'error' : ''}
                    />
                    {touched.assigneeEmail && errors.assigneeEmail && (
                      <div className="error-message">{errors.assigneeEmail}</div>
                    )}
                  </Form.Group>

                  <div className="title">New assignee details</div>
                  <Form.Group controlId="formBasicCompanyName" className="form-input">
                    <Form.Label>Company name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter company name"
                      name="attorneyCompany"
                      onChange={handleChange}
                      value={values.attorneyCompany}
                      className={touched.attorneyCompany && errors.attorneyCompany ? 'error' : ''}
                    />
                    {touched.attorneyCompany && errors.attorneyCompany && (
                      <div className="error-message">{errors.attorneyCompany}</div>
                    )}
                  </Form.Group>
                  <Form.Group controlId="formBasicAttorneyName" className="form-input">
                    <Form.Label>Attorney name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter attorney name"
                      name="attorneyName"
                      onChange={handleChange}
                      value={values.attorneyName}
                      className={touched.attorneyName && errors.attorneyName ? 'error' : ''}
                    />
                    {touched.attorneyName && errors.attorneyName && (
                      <div className="error-message">{errors.attorneyName}</div>
                    )}
                  </Form.Group>
                  <Form.Group controlId="formBasicAttorneyEmail" className="form-input">
                    <Form.Label>Attorney email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter attorney email"
                      name="attorneyEmail"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.attorneyEmail}
                      className={touched.attorneyEmail && errors.attorneyEmail ? 'error' : ''}
                    />
                    {touched.attorneyEmail && errors.attorneyEmail && (
                      <div className="error-message">{errors.attorneyEmail}</div>
                    )}
                  </Form.Group>
                  <Form.Group controlId="formBasicAddressLineOne" className="form-input">
                    <Form.Label>Address Line 1</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter address line 1"
                      name="addressLineOne"
                      onChange={handleChange}
                      value={values.addressLineOne}
                      className={touched.addressLineOne && errors.addressLineOne ? 'error' : ''}
                    />
                    {touched.addressLineOne && errors.addressLineOne && (
                      <div className="error-message">{errors.addressLineOne}</div>
                    )}
                  </Form.Group>
                  <Form.Group controlId="formBasicAddressLineTwo" className="form-input">
                    <Form.Label>Address Line Two</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter address line 2"
                      name="addressLineTwo"
                      onChange={handleChange}
                      value={values.addressLineTwo}
                      className={touched.addressLineTwo && errors.addressLineTwo ? 'error' : ''}
                    />
                    {touched.addressLineTwo && errors.addressLineTwo && (
                      <div className="error-message">{errors.addressLineTwo}</div>
                    )}
                  </Form.Group>
                  <div
                    className={
                      initialData
                        ? 'd-flex p-0 col-8 col-lg-6 justify-content-between'
                        : 'd-flex p-0 col-5 col-lg-4 justify-content-between'
                    }
                  >
                    {initialData ? (
                      <>
                        <Button variant="warning" type="submit" disabled={isSubmitting}>
                          Save
                        </Button>
                        <Button
                          variant="danger"
                          type="button"
                          disabled={isSubmitting}
                          onClick={() => {
                            setFieldValue('internalStatus', 'WaitingForInventorSignature', false)
                            handleSubmit()
                          }}
                        >
                          Send to Inventor
                        </Button>
                      </>
                    ) : (
                      <Button variant="danger" type="submit" disabled={isSubmitting}>
                        Submit for reassignment
                      </Button>
                    )}
                    <Button variant="outline-danger" onClick={listOwnershipCallback}>
                      Discard
                    </Button>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </Col>
      </Row>
    </div>
  )
}

export default InventorAssignmentForm
