import React, { useEffect, useState } from 'react'
import { Table, Button } from 'react-bootstrap'
import './css/InventorAssignmentList.css'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import InventorAssignmentModal from './InventorAssignmentModal'
import { getAssignments, sendContractForSignature, sendAssignmentInstruction } from './utils'

const initalState = {
  customerReference: '',
  filingDate: '',
  createdAt: '',
  country: '',
  oldAssigneeFullName: '',
  newAssigneeFullName: '',
  companyAttorneyFullName: '',
  newAssigneeCompany: '',
  oldAssigneeCompany: '',
  status: '',
}

const statuses = {
  New: 'New',
  WaitingForAssignmentInstruction: 'Waiting For Assignment Instruction',
  SendAssignmentInstructionToPto: 'Send Assignment Instruction to PTO',
  WaitingForInventorSignature: 'Waiting For Inventor Signature',
  WaitingForAttorneySignature: 'Waiting For Attorney Signature',
  AssignmentInstructionSentToPto: 'Assignment Instruction Sent to PTO',
  AssignmentInstructionReceived: 'Assignment Instruction Received',
  AssignmentInstructionReceivedByPTO: 'Confirmed by PTO',
  Completed: 'Completed',
}

const Contract = ({ status, focus, sendContract, sendInstruction }) => {
  if (status === 'WaitingForAssignmentInstruction') return <td style={{ width: '150px' }}>{statuses[status]}</td>
  if (status === 'SendAssignmentInstructionToPto' && focus)
    return (
      <td className="download-contract" onClick={sendInstruction}>
        <div className="view">Send Assignment Instruction</div>
      </td>
    )
  if (status === 'SendAssignmentInstructionToPto') return <td style={{ width: '150px' }}>{statuses[status]}</td>
  if (status === 'WaitingForInventorSignature') return <td style={{ width: '150px' }}>{statuses[status]}</td>
  if (status === 'WaitingForAttorneySignature') return <td style={{ width: '150px' }}>{statuses[status]}</td>
  if (status === 'New' && focus)
    return (
      <td className="download-contract" onClick={sendContract}>
        <div className="view">Send to Inventor</div>
      </td>
    )
  if (status === 'New') return <td style={{ width: '150px' }}>{statuses[status]}</td>
  if (status === 'AssignmentInstructionSentToPto') return <td style={{ width: '150px' }}>{statuses[status]}</td>
  if (status === 'AssignmentInstructionReceived') return <td style={{ width: '150px' }}>{statuses[status]}</td>
  if (status === 'AssignmentInstructionReceivedByPTO') return <td style={{ width: '150px' }}>{statuses[status]}</td>
  return <td style={{ width: '150px' }}>{status}</td>
}

const InventorAssignmentList = ({ createOwnershipCallback, modifyAssignmentCallback = false }) => {
  const [filter, updateSearch] = useState(initalState)
  const [focusedRow, updateFocusedRow] = useState(-1)
  const [showModal, updateModal] = useState(false)
  const [modalData, updateModalData] = useState(undefined)
  const [assignments, updateAssignments] = useState([])

  useEffect(() => {
    async function fetchData() {
      updateAssignments(await getAssignments())
    }
    fetchData()
  }, [showModal])

  const nestedFilter = (targetArray) => {
    if (targetArray.length === 0) return targetArray
    let rawKeys = Object.keys(filter)
    let filterKeys = rawKeys.filter((key) => filter[key] !== '')
    return targetArray.filter((row) =>
      filterKeys.every((filterKey) => {
        if (filterKey === 'oldAssigneeFullName') {
          return row['oldAssignee'].name
            .toString()
            .toLocaleLowerCase()
            .includes(filter[filterKey].toString().toLocaleLowerCase())
        }
        if (filterKey === 'newAssigneeFullName') {
          return row['newAssignee'].name
            .toString()
            .toLocaleLowerCase()
            .includes(filter[filterKey].toString().toLocaleLowerCase())
        }
        if (filterKey === 'oldAssigneeCompany') {
          return row['oldAssignee'].company
            .toString()
            .toLocaleLowerCase()
            .includes(filter[filterKey].toString().toLocaleLowerCase())
        }
        if (filterKey === 'newAssigneeCompany') {
          return row['newAssignee'].company
            .toString()
            .toLocaleLowerCase()
            .includes(filter[filterKey].toString().toLocaleLowerCase())
        }
        return row[filterKey].toString().toLocaleLowerCase().includes(filter[filterKey].toString().toLocaleLowerCase())
      })
    )
  }

  const populateModal = (data) => {
    const legibleData = data
    legibleData.status = statuses[data.status] || 'Completed'
    updateModalData(legibleData)
    updateModal(true)
  }

  const tableData = nestedFilter(assignments)
  return (
    <div className="assignment">
      <div className="assignments-header">
        <div className="assignments-header-text">Change of ownership</div>
        <div className="assignments-header-filter">
          <Button onClick={createOwnershipCallback} variant="danger">
            Create change of ownership
          </Button>
        </div>
      </div>
      <Table hover>
        <thead>
          <tr>
            <th>Assignment</th>
            <th>Customer Reference</th>
            <th>Creation Date</th>
            <th>Original Inventor</th>
            <th>Original Inventor Company</th>
            <th>New Assignee</th>
            <th>New Assignee Company</th>
            <th>Status</th>
          </tr>
          <tr>
            <th />
            <th>
              <InputGroup>
                <FormControl
                  placeholder="reference"
                  aria-label="Customer Reference"
                  aria-describedby="basic-addon1"
                  value={filter.customerReference}
                  onChange={(e) => updateSearch({ ...filter, customerReference: e.target.value })}
                />
                <InputGroup.Append>
                  <Button
                    variant="outline-secondary"
                    onClick={() => updateSearch({ ...filter, customerReference: '' })}
                  >
                    X
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </th>
            <th>
              <InputGroup>
                <FormControl
                  placeholder="date"
                  aria-label="Date"
                  aria-describedby="basic-addon1"
                  type="date"
                  value={filter.createdAt}
                  onChange={(e) => updateSearch({ ...filter, createdAt: e.target.value })}
                />
                <InputGroup.Append>
                  <Button variant="outline-secondary" onClick={() => updateSearch({ ...filter, createdAt: '' })}>
                    X
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </th>
            <th>
              <InputGroup>
                <FormControl
                  placeholder="original Inventor"
                  aria-label="Original Inventor"
                  aria-describedby="basic-addon1"
                  value={filter.oldAssigneeFullName}
                  onChange={(e) => updateSearch({ ...filter, oldAssigneeFullName: e.target.value })}
                />
                <InputGroup.Append>
                  <Button
                    variant="outline-secondary"
                    onClick={() => updateSearch({ ...filter, oldAssigneeFullName: '' })}
                  >
                    X
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </th>
            <th>
              <InputGroup>
                <FormControl
                  placeholder="company"
                  aria-label="Company"
                  aria-describedby="basic-addon1"
                  value={filter.oldAssigneeCompany}
                  onChange={(e) => updateSearch({ ...filter, oldAssigneeCompany: e.target.value })}
                />
                <InputGroup.Append>
                  <Button
                    variant="outline-secondary"
                    onClick={() => updateSearch({ ...filter, oldAssigneeCompany: '' })}
                  >
                    X
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </th>
            <th>
              <InputGroup>
                <FormControl
                  placeholder="new assignee"
                  aria-label="Company"
                  aria-describedby="basic-addon1"
                  value={filter.newAssigneeFullName}
                  onChange={(e) => updateSearch({ ...filter, newAssigneeFullName: e.target.value })}
                />
                <InputGroup.Append>
                  <Button
                    variant="outline-secondary"
                    onClick={() => updateSearch({ ...filter, newAssigneeFullName: '' })}
                  >
                    X
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </th>
            <th>
              <InputGroup>
                <FormControl
                  placeholder="company"
                  aria-label="Company"
                  aria-describedby="basic-addon1"
                  value={filter.newAssigneeCompany}
                  onChange={(e) => updateSearch({ ...filter, newAssigneeCompany: e.target.value })}
                />
                <InputGroup.Append>
                  <Button
                    variant="outline-secondary"
                    onClick={() => updateSearch({ ...filter, newAssigneeCompany: '' })}
                  >
                    X
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </th>
            <th className="d-flex">
              <DropdownButton
                id="dropdown"
                variant="outline-secondary"
                title={filter.status === '' ? 'Status' : statuses[filter.status]}
              >
                {Object.keys(statuses).map((status) => (
                  <Dropdown.Item onClick={() => updateSearch({ ...filter, status })}>{statuses[status]}</Dropdown.Item>
                ))}
              </DropdownButton>
              <Button variant="outline-secondary" onClick={() => updateSearch({ ...filter, status: '' })}>
                X
              </Button>
            </th>
          </tr>
        </thead>
        <tbody>
          {tableData
            .sort((first, second) => {
              let fStart = new Date(first.updatedAt).valueOf()
              let sStart = new Date(second.updatedAt).valueOf()
              return sStart - fStart
            })
            .map((data, index) => {
              return (
                <tr
                  key={index}
                  className="assignment-row"
                  onMouseEnter={() => updateFocusedRow(index)}
                  onMouseLeave={() => updateFocusedRow(-1)}
                >
                  <td>
                    <Button variant={'outline-secondary'} onClick={() => populateModal(data)}>
                      View
                    </Button>
                  </td>
                  <td>{data.customerReference}</td>
                  <td>{new Date(data.createdAt).toLocaleDateString()}</td>
                  <td>{data.oldAssignee.name}</td>
                  <td>{data.oldAssignee.company}</td>
                  <td>{data.newAssignee.name}</td>
                  <td>{data.newAssignee.company}</td>

                  <Contract
                    status={data.status}
                    focus={index === focusedRow}
                    sendContract={async () => {
                      await sendContractForSignature(data)
                      window.location.reload()
                    }}
                    sendInstruction={async () => {
                      await sendAssignmentInstruction(data.id)
                      window.location.reload()
                    }}
                  />
                </tr>
              )
            })}
        </tbody>
      </Table>
      <InventorAssignmentModal
        data={modalData}
        show={showModal}
        close={() => {
          updateModalData(undefined)
          updateModal(false)
        }}
        modifyAssignmentCallback={modifyAssignmentCallback}
      />
    </div>
  )
}

export default InventorAssignmentList
