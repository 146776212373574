import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { Button, Table } from 'react-bootstrap'
import { pushDownloadFile } from './utils'

const getDoc = async (id) => {
  return await pushDownloadFile(window._env_.REACT_APP_BASEURL + '/v1/ip/assignment/envelope/' + id)
}

const getEnvelopeIdFromDocURL = (url) => {
  if (!url) return
  return url.split('/').slice(-1)
}

const InventorAssignmentModal = ({ data, show, close, modifyAssignmentCallback }) => {
  if (!data) {
    return null
  }

  const { payload } = data

  return (
    <Modal size="md" show={show} onHide={close} aria-labelledby="example-modal-sizes-title-md">
      <Modal.Header>
        <Modal.Title id="example-modal-sizes-title-md">All information on assignment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {data.status === 'New' && (
          <div className="container-fluid py-3">
            <Button
              variant={'secondary'}
              onClick={() => {
                modifyAssignmentCallback(data)
              }}
              block
            >
              Edit Assignment
            </Button>
          </div>
        )}

        <Table>
          <tbody>
            <tr>
              <td>Customer Reference:</td>
              <td>{data.customerReference}</td>
            </tr>
            {payload.title && (
              <tr>
                <td>Title:</td>
                <td>{payload.title}</td>
              </tr>
            )}
            {data.country && (
              <tr>
                <td>Country</td>
                <td>{data.country}</td>
              </tr>
            )}
            {payload.applicationNumber && (
              <tr>
                <td>Application Number:</td>
                <td>{payload.applicationNumber}</td>
              </tr>
            )}
            <tr>
              <td>Filing Date:</td>
              <td>{data.filingDate}</td>
            </tr>
            <tr>
              <td>Old Assignee Name:</td>
              <td>{data.oldAssignee.name}</td>
            </tr>
            <tr>
              <td>Old Assignee Email</td>
              <td>{data.oldAssignee.email}</td>
            </tr>
            <tr>
              <td>Old Assignee Company:</td>
              <td>{data.oldAssignee.company}</td>
            </tr>
            <tr>
              <td>New Assignee Name:</td>
              <td>{data.newAssignee.name}</td>
            </tr>
            <tr>
              <td>New Assignee Email:</td>
              <td>{data.newAssignee.email}</td>
            </tr>
            <tr>
              <td>New Assignee Company</td>
              <td>{data.newAssignee.company}</td>
            </tr>
            <tr>
              <td>Created At:</td>
              <td>{new Date(data.createdAt).toLocaleDateString()}</td>
            </tr>
            {payload.addressLineOne && (
              <tr>
                <td>Address Line 1:</td>
                <td>{payload.addressLineOne}</td>
              </tr>
            )}
            {payload.addressLineTwo && (
              <tr>
                <td>Address Line 2:</td>
                <td>{payload.addressLineTwo}</td>
              </tr>
            )}

            <tr>
              <td>Status:</td>
              <td>{data.status}</td>
            </tr>
            {data.status !== 'New' && (
              <tr>
                <td className="download-contract">
                  <Button
                    variant={'outline-secondary'}
                    onClick={() => getDoc(getEnvelopeIdFromDocURL(data.documentURL) || data.contract.envelopeId)}
                  >
                    View Contract
                  </Button>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  )
}

export default InventorAssignmentModal
