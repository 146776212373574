import React from 'react'
import logo from '../logo.svg'
import '../css/Header.css'

const Header = () => (
  <div className="header">
    <img alt="Logo" className="logo" src={logo} />
  </div>
)

export default Header
